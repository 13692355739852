

export default [
  {
    title: 'Anasayfa',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Profil',
    route: 'profile',
    icon: 'UserIcon',
  },
  
  {
    title: 'Verimlilik',
    route:'productivity',
    icon: 'TrendingUpIcon',
    // children:[
    //   {
    //     title: 'User Detail',
    //     route: 'profile',
    //   }
    // ]
  },
  {
   
    title: 'Ayarlar',
    route: 'settings',
    icon: 'SettingsIcon',
    children:[
      {
        title: 'Kullanıcı',
        route: 'user-settings',
        icon: 'UserIcon',
      },
      
      {
        title: 'Departman',
        route: 'department-settings',
        icon: 'GridIcon',
      },
      {
        title: 'Proje',
        route: 'project-settings',
        icon: 'FileIcon',
      },
      {
        title: 'Verimlilik',
        route: 'productivity-settings',
        icon: 'TrendingUpIcon',
      },
      {
        title: 'Raporlar',
        route: 'reports',
        icon: 'FileIcon',
      },
    ]
  },
  
  
  
]
