<template>
  <div class="navbar-container d-flex content align-items-center" >
    <div class="d-flex justify-content-center mb-1 m-auto" variant="warning" v-if="isLoading">
      <strong class="mr-2"> Yükleniyor...</strong>
      <b-spinner class="m-auto ml-4" variant="warning" />
    </div>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" v-if="!isLoading">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
<!-- lll{{ windowWidth }}
{{ screenWidthValue }} -->
    <!-- Left Col -->
    <div v-if="!isLoading" class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
     

      <b-nav v-if="isHome">
        <b-button variant="outline-success" size="sm" disabled class="float-left selected-department "><label>Seçilen
            Departman</label><br>{{ selectedRadio }}
        </b-button>
        <div class="vertical-line"></div>
        <div v-for="(item, index) in optionsRadio " :key="index" v-if="index<screenWidthValue">
          <b-nav-item  @click="btnClick(item)"  v-model="selectedDepartmentId" class="ml-5"
            :class="{ 'active': selectedRadio === optionsRadio[index].text }">
            <b-button  class="dep-btn">{{ item.text }}</b-button>
          </b-nav-item>

        </div>

        <!-- optionsRadio.findIndex(item => item.text === selectedRadio) > 1 ? optionsRadio[optionsRadio.findIndex(item => item.text === selectedRadio)].text : -->
        <b-dropdown  v-if="optionsRadio.length > screenWidthValue" v-ripple.400="'rgba(113, 102, 240, 0.15)'" block right
          split-variant="outline-primary"
          :text=" 'Diğer Departmanlar'"
          
          :variant="optionsRadio.findIndex(item => item.text === selectedRadio) > 1 ? 'outline-primary' : 'outline-primary'"
          class="d-flex align-items-center  ml-5">
          <b-dropdown-item v-for="(item, index) in optionsRadio" :key="index" v-if="index > 1"
            @click="btnClick(item)" >{{ item.text }}</b-dropdown-item>

        </b-dropdown>
      </b-nav>

      <!-- departments end-->
    </div>
 
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              <!-- {{ userData.name + ' ' + userData.surname }} -->
              {{ capitalizedFullName }}
            </p>
            
            <span class="user-status">{{ userData.generalRole == "Admin" ? 'Yönetici' : 'Çalışan' }}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src='baseUrl+userData.image'
            class="badge-minimal" badge-variant="success" />
        </template>
        <!-- {{ userData }} -->
        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profil</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span >Çıkış yap</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BDropdown, BNav, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import vSelect from 'vue-select'
import { BButtonGroup, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Api } from '@/helpers/Api/apiConfig'
import { BSpinner } from "bootstrap-vue";
import {BASE_URL}  from '@/helpers/Url/BaseUrl'
import { BFormRadioGroup, BFormCheckboxGroup, BFormGroup, BRow, BCol } from 'bootstrap-vue'
export default {
  data() {
    return {
      screenWidthValue:1,
      isLoading: false,
      baseUrl:BASE_URL,
      ///slider menu
      currentIndex: 0,
      selectedDepartmentId:'',
      windowWidth: window.innerWidth,
      ///slider menu end
      userData: JSON.parse(localStorage.getItem('userData')),
      isHome: false,
      departments: [],
      selectedRadio: 'Tümü',
      optionsRadio: [
        { text: 'Active', value: 'active' },
        { text: 'Radio', value: 'radio' },
        { text: 'Radio', value: 'radio2' },
      ],
    }

  },
  components: {
    BASE_URL,
    BSpinner,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    vSelect,
    BNav, BNavItem,
    // Navbar Components
    DarkToggler,
    //btn
    BButtonGroup,
    BButton,
    //radio
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormGroup,
    BRow,
    BCol,
    BDropdown,

  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    }
  },
  beforeDestroy() {
  window.removeEventListener('resize', this.handleResize);
},
  mounted() {
    window.addEventListener('resize', this.handleResize);
   
    this.getDepartments()
  },
  computed: {
    capitalizedFullName() {
      const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      return capitalize(this.userData.name) + ' ' + capitalize(this.userData.surname);
    }
  },
  watch: {
    screenWidthValue(val) {
      this.screenWidthValue = val
    },
    selectedDepartmentId(newDepartment) {
     this.selectedDepartmentId=newDepartment
      console.log('dd',newDepartment);
      this.$router.push({ query: { department: newDepartment } });
    },

    "$route.path": {
      handler: function (value) {
        // alert(this.$route.name );

        // productivity route'ı ise burada yapılacak işlemler
        //this.$route.name == '/' 
        value == '/'   ? this.isHome = true : this.isHome = false


      },
      deep: true,
      immediate: true,
    },
    selectedRadio(val) {
      this.selectedRadio = val
    },
    isLoading(val) {
      this.isLoading = val
    },
    optionsRadio(val) {
      this.optionsRadio=val

    }
  },

  methods: {
    handleResize() {
      // alert(window.innerWidth)
    // Update the width value in your data property
    this.windowWidth = window.innerWidth;
    if(this.windowWidth<1360){
      this.screenWidthValue=1
    }else if(this.windowWidth>1360 && this.windowWidth<1400){
      this.screenWidthValue=1
    }else if(this.windowWidth>1400 && this.windowWidth<1600){
      this.screenWidthValue=2
    }
    else if(this.windowWidth>1600 && this.windowWidth<1800){
      this.screenWidthValue=3
    }
    else if(this.windowWidth>1800 ){
      this.screenWidthValue=4
    }
  },
    ///slider menu
btnClick(item){
this.selectedRadio=item.text
this.selectedDepartmentId = item.id
},



    ///slider menu end
    async getDepartments() {
      this.isLoading = true
      await Api('GET', 'Department').then(res => {
        console.log('ressnavbar', res);
        this.departments = res.data.data.items
        this.optionsRadio = this.departments.map((item, index) => {
          return {
            text: item.name,
            //  value: false
            id:item.departmentId
            
          }
        })
        this.optionsRadio.unshift({ text: 'Tümü',id:''})
        console.log('departmanlar', this.optionsRadio);
        this.handleResize();
      })
      this.isLoading = false
     
    },
    logout() {
      localStorage.removeItem('userData');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.$router.push({ name: 'auth-login' });
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';

// .dropdown-menu {
//   min-width: 300px !important;

// }
.active {

  .btn-secondary {
    border-color: #7367F0 !important;
    background-color: #7367F0 !important;
    color: #fff !important;
    &:focus{
      color: #fff !important;
      background-color: #7367F0 !important;
    }
    &:hover {
      color: #fff !important;
      background-color: #7367F0 !important;
  
    }
  }


}

// .dropdown-toggle::after {
//   color: white;
//    background-color: #dfdfdf !important;
//     width: 14px;
//     height: 11px;
//     position: relative;
//     top: 1px;
//     vertical-align: 0;
// }
.btn-secondary {
  color: #7367f0 ;
  border-color: #dfdfdf ;
  background-color: #dfdfdf ;

  &:hover {
    color: #fff !important;
    background-color: #7367F0 !important;

  }
  &:focus{
    color: #fff !important;
    background-color: #7367F0 !important;
  }
  &:active{
    color: #fff !important;
    background-color: #7367F0 !important;
  }

}

.selected-department {
  min-width: 200px;
}
</style>
